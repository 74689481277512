@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";

/*menu bar*/
.navbar-brand {
    color: #FF971A;
    font-size: 1.8em;
    font-weight: bold;
}
.navbar-brand:hover {
    color: #FF971A;
}

.navbar-brand img{
    max-width: 64px;
}

.navbar .nav-link {
    font-size: 1em;
    color: #FF971A;
}

.navbar .nav-link:hover {
    color: red;
}

.navbar-nav .nav-link {
    color: #000;
    font-size: 1.2em;
}

/* màu chữ mục báo giảng */
.item-link, .item-link:visited {
    color:blue;
}
.item-link:hover {
    color: red;
}

#topNav .nav-item{
    padding-right:5px;
}

#workplan-container .form-control, #workplan-container .form-select{
    display: inline-block;
    width: inherit;
}
#workplan-container .react-datepicker-wrapper {
    width: inherit !important;
}

/* ==========================================
*  file input phần import TKB
* ==========================================*/
.custom-file {
    display: flex; 
    align-items: center;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Chọn file";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
} 

/* ==========================================
*  Table fixed header
* ==========================================*/
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;
    &::after {
        content: '';
        clear: both;
        display: block;
    }
}